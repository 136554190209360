<template>
    <div style="width: 100%;" v-loading="loading">
        <div style="width: 96%; margin-left:2%; height:96%; border: 1px solid #ddd; margin-top: 20px;">
            <p style="border-left: 4px solid blue; line-height: 20px; margin: 20px; padding-left: 10px;"></p>
            <div>
                <table style="width: 100%; margin-top: -10px; line-height: 38px;">
                    <el-row :gutter="11" style="margin-left: 10px;">
                        <el-col v-if="total.value1 != null" :span="11">
                            <el-card shadow="always">
                                <div style="font-size: 30px;font-weight: bold;">{{total.value4}}</div>
                                <div>商户通道ID:</div>
                                <div style="font-size: 20px;font-weight: bold;">{{total.value1}}</div>
                                <div style="border: 1px dashed black;"></div>
                                <table class="ta">
                                    <tr>
                                        <th>代收</th>
                                        <th>代付</th>
                                    </tr>
                                    <tr>
                                        <th>商户服务费(%): {{total.value2}}</th>
                                        <th>商户服务费(%): {{total.value3}}</th>
                                    </tr>
                                    <tr>
                                        <th>商户服务费(最低每笔): 6</th>
                                        <th>商户服务费(最低每笔): 6</th>
                                    </tr>
                                </table>
                                <div style="border: 1px dashed black;"></div>
                                <table style="margin-top: 10px; line-height: 38px;width: 800px;text-align: left;width: 765px;">
                                    <tr>
                                        <th>提现信息</th>
                                        <th>手动提现</th>
                                    </tr>
                                    <tr>
                                        <th>提现服务费(%): {{total.value9}}</th> 
                                        <th>单笔提现服务费(上限): 100,000.00</th>
                                    </tr>
                                    <tr>
                                        <th colspan="2">提现周期: D0</th>
                                    </tr>
                                    <tr>
                                        <th>每日最大提现金额: 5,000,000.00</th>
                                        <th>每日提现次数(非代付): 3</th>
                                    </tr>
                                    <tr>
                                        <th>单笔最大提现金额: 2,000,000.00  </th>         
                                        <th>提现开始时间: 00:00:00</th>
                                    </tr>
                                    <tr>
                                        <th>单笔最小提现金额: 200,000.00   </th>         
                                        <th>提现结束时间: 23:59:59</th>
                                    </tr>
                                    <tr><th colspan="2">提现日期: 周一、周二、周三、周四、周五</th></tr>
                                </table>
                            </el-card>
                        </el-col>
                        <el-col v-if="total.value8 != null" :span="11">
                            <el-card shadow="always">
                                <div style="font-size: 30px;font-weight: bold;">{{total.value8}}</div>
                                <div>商户通道ID:</div>
                                <div style="font-size: 20px;font-weight: bold;">{{total.value5}}</div>
                                <div style="border: 1px dashed black;"></div>
                                <table class="ta">
                                    <tr>
                                        <th>代收</th>
                                        <th>代付</th>
                                    </tr>
                                    <tr>
                                        <th>商户服务费(%): {{total.value6}}</th>
                                        <th>商户服务费(%): {{total.value7}}</th>
                                    </tr>
                                    <tr>
                                        <th>商户服务费(最低每笔): 20</th>
                                        <th>商户服务费(最低每笔): 0</th>
                                    </tr>
                                </table>
                                <div style="border: 1px dashed black;"></div>
                                <table style="margin-top: 10px; line-height: 38px;width: 800px;text-align: left;width: 765px;">
                                    <tr>
                                        <th>提现信息</th>
                                        <th>手动提现</th>
                                    </tr>
                                    <tr>
                                        <th>提现服务费(%): {{total.value10}}</th> 
                                        <th>单笔提现服务费(上限): 1,000.00</th>
                                    </tr>
                                    <tr>
                                        <th colspan="2">提现周期: D0</th>
                                    </tr>
                                    <tr>
                                        <th>每日最大提现金额: 5,000,000.00</th>
                                        <th>每日提现次数(非代付): 3</th>
                                    </tr>
                                    <tr>
                                        <th>单笔最大提现金额: 2,000,000.00  </th>         
                                        <th>提现开始时间: 00:00:00</th>
                                    </tr>
                                    <tr>
                                        <th>单笔最小提现金额: 200,000.00   </th>         
                                        <th>提现结束时间: 23:59:59</th>
                                    </tr>
                                    <tr><th colspan="2">提现日期: 周一、周二、周三、周四、周五</th></tr>
                                </table>
                            </el-card>
                        </el-col>

                        <el-col v-if="total.value12 != null" :span="11" style="margin-top: 20px;">
                            <el-card shadow="always">
                                <div style="font-size: 30px;font-weight: bold;">{{total.value14}}</div>
                                <div>商户通道ID:</div>
                                <div style="font-size: 20px;font-weight: bold;">{{total.value11}}</div>
                                <div style="border: 1px dashed black;"></div>
                                <table class="ta">
                                    <tr>
                                        <th>代收</th>
                                        <th>代付</th>
                                    </tr>
                                    <tr>
                                        <th>商户服务费(%): {{total.value12}}</th>
                                        <th>商户服务费(%): {{total.value13}}</th>
                                    </tr>
                                    <tr>
                                        <th>商户服务费(最低每笔): 6</th>
                                        <th>商户服务费(最低每笔): 0</th>
                                    </tr>
                                </table>
                                <div style="border: 1px dashed black;"></div>
                                <table style="margin-top: 10px; line-height: 38px;width: 800px;text-align: left;width: 765px;">
                                    <tr>
                                        <th>提现信息</th>
                                        <th>手动提现</th>
                                    </tr>
                                    <tr>
                                        <th>提现服务费(%): {{total.value15}}</th> 
                                        <th>单笔提现服务费(上限): 1,000.00</th>
                                    </tr>
                                    <tr>
                                        <th colspan="2">提现周期: D0</th>
                                    </tr>
                                    <tr>
                                        <th>每日最大提现金额: 5,000,000.00</th>
                                        <th>每日提现次数(非代付): 3</th>
                                    </tr>
                                    <tr>
                                        <th>单笔最大提现金额: 2,000,000.00</th>         
                                        <th>提现开始时间: 00:00:00</th>
                                    </tr>
                                    <tr>
                                        <th>单笔最小提现金额: 200,000.00</th>         
                                        <th>提现结束时间: 23:59:59</th>
                                    </tr>
                                    <tr><th colspan="2">提现日期: 周一、周二、周三、周四、周五</th></tr>
                                </table>
                            </el-card>
                        </el-col>

                        <el-col v-if="total.value17 != null" :span="11" style="margin-top: 20px;">
                            <el-card shadow="always">
                                <div style="font-size: 30px;font-weight: bold;">{{total.value19}}</div>
                                <div>商户通道ID:</div>
                                <div style="font-size: 20px;font-weight: bold;">{{total.value16}}</div>
                                <div style="border: 1px dashed black;"></div>
                                <table class="ta">
                                    <tr>
                                        <th>代收</th>
                                        <th>代付</th>
                                    </tr>
                                    <tr>
                                        <th>商户服务费(%): {{total.value17}}</th>
                                        <th>商户服务费(%): {{total.value18}}</th>
                                    </tr>
                                    <tr>
                                        <th>商户服务费(最低每笔): 6</th>
                                        <th>商户服务费(最低每笔): 0</th>
                                    </tr>
                                </table>
                                <div style="border: 1px dashed black;"></div>
                                <table style="margin-top: 10px; line-height: 38px;width: 800px;text-align: left;width: 765px;">
                                    <tr>
                                        <th>提现信息</th>
                                        <th>手动提现</th>
                                    </tr>
                                    <tr>
                                        <th>提现服务费(%): {{total.value20}}</th> 
                                        <th>单笔提现服务费(上限): 1,000.00</th>
                                    </tr>
                                    <tr>
                                        <th colspan="2">提现周期: D0</th>
                                    </tr>
                                    <tr>
                                        <th>每日最大提现金额: 5,000,000.00</th>
                                        <th>每日提现次数(非代付): 3</th>
                                    </tr>
                                    <tr>
                                        <th>单笔最大提现金额: 2,000,000.00  </th>         
                                        <th>提现开始时间: 00:00:00</th>
                                    </tr>
                                    <tr>
                                        <th>单笔最小提现金额: 200,000.00   </th>         
                                        <th>提现结束时间: 23:59:59</th>
                                    </tr>
                                    <tr><th colspan="2">提现日期: 周一、周二、周三、周四、周五</th></tr>
                                </table>
                            </el-card>
                        </el-col>

                        <el-col v-if="total.value21 != null" :span="11" style="margin-top: 20px;">
                            <el-card shadow="always">
                                <div style="font-size: 30px;font-weight: bold;">{{total.value24}}</div>
                                <div>商户通道ID:</div>
                                <div style="font-size: 20px;font-weight: bold;">{{total.value21}}</div>
                                <div style="border: 1px dashed black;"></div>
                                <table class="ta">
                                    <tr>
                                        <th>代收</th>
                                        <th>代付</th>
                                    </tr>
                                    <tr>
                                        <th>商户服务费(%): {{total.value22}}</th>
                                        <th>商户服务费(%): {{total.value23}}</th>
                                    </tr>
                                    <tr>
                                        <th>商户服务费(最低每笔): 6</th>
                                        <th>商户服务费(最低每笔): 0</th>
                                    </tr>
                                </table>
                                <div style="border: 1px dashed black;"></div>
                                <table style="margin-top: 10px; line-height: 38px;width: 800px;text-align: left;width: 765px;">
                                    <tr>
                                        <th>提现信息</th>
                                        <th>手动提现</th>
                                    </tr>
                                    <tr>
                                        <th>提现服务费(%): {{total.value25}}</th> 
                                        <th>单笔提现服务费(上限): 1,000.00</th>
                                    </tr>
                                    <tr>
                                        <th colspan="2">提现周期: D0</th>
                                    </tr>
                                    <tr>
                                        <th>每日最大提现金额: 5,000,000.00</th>
                                        <th>每日提现次数(非代付): 3</th>
                                    </tr>
                                    <tr>
                                        <th>单笔最大提现金额: 2,000,000.00  </th>         
                                        <th>提现开始时间: 00:00:00</th>
                                    </tr>
                                    <tr>
                                        <th>单笔最小提现金额: 200,000.00   </th>         
                                        <th>提现结束时间: 23:59:59</th>
                                    </tr>
                                    <tr><th colspan="2">提现日期: 周一、周二、周三、周四、周五</th></tr>
                                </table>
                            </el-card>
                        </el-col>

                        <el-col v-if="total.value26 != null" :span="11" style="margin-top: 20px;">
                            <el-card shadow="always">
                                <div style="font-size: 30px;font-weight: bold;">{{total.value29}}</div>
                                <div>商户通道ID:</div>
                                <div style="font-size: 20px;font-weight: bold;">{{total.value26}}</div>
                                <div style="border: 1px dashed black;"></div>
                                <table class="ta">
                                    <tr>
                                        <th>代收</th>
                                        <th>代付</th>
                                    </tr>
                                    <tr>
                                        <th>商户服务费(%): {{total.value27}}</th>
                                        <th>商户服务费(%): {{total.value28}}</th>
                                    </tr>
                                    <tr>
                                        <th>商户服务费(最低每笔): 6</th>
                                        <th>商户服务费(最低每笔): 0</th>
                                    </tr>
                                </table>
                                <div style="border: 1px dashed black;"></div>
                                <table style="margin-top: 10px; line-height: 38px;width: 800px;text-align: left;width: 765px;">
                                    <tr>
                                        <th>提现信息</th>
                                        <th>手动提现</th>
                                    </tr>
                                    <tr>
                                        <th>提现服务费(%): {{total.value30}}</th> 
                                        <th>单笔提现服务费(上限): 1,000.00</th>
                                    </tr>
                                    <tr>
                                        <th colspan="2">提现周期: D0</th>
                                    </tr>
                                    <tr>
                                        <th>每日最大提现金额: 5,000,000.00</th>
                                        <th>每日提现次数(非代付): 3</th>
                                    </tr>
                                    <tr>
                                        <th>单笔最大提现金额: 2,000,000.00  </th>         
                                        <th>提现开始时间: 00:00:00</th>
                                    </tr>
                                    <tr>
                                        <th>单笔最小提现金额: 200,000.00   </th>         
                                        <th>提现结束时间: 23:59:59</th>
                                    </tr>
                                    <tr><th colspan="2">提现日期: 周一、周二、周三、周四、周五</th></tr>
                                </table>
                            </el-card>
                        </el-col>
                    </el-row>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import apiurl from "@/api/public"
import _api from "@/api/index"
export default {
    data(){
        return{
            loading:false,
            total:{
                value1: 0,
                value2: 0,
                value3: 0,
                value4: 0,
                value5: 0,
                value6: 0,
                value7: 0,
                value8: 0,
                value9: 0,
                value10: 0,

                value11: 0,
                value12: 0,
                value13: 0,
                value14: 0,
                value15: 0,

                value16: 0,
                value17: 0,
                value18: 0,
                value19: 0,
                value20: 0,

                value21: 0,
                value22: 0,
                value23: 0,
                value24: 0,
                value25: 0,

                value26: 0,
                value27: 0,
                value28: 0,
                value29: 0,
                value30: 0,
            },
        }
    },
    created(){
        this.getTotal();
    },
    methods:{
        getTotal(){
            this.loading = true
            _api.get(apiurl.channel,{
            }).then(res=>{
                this.loading = false
                this.total.value1=res.data.value1
                this.total.value2=res.data.value2
                this.total.value3=res.data.value3
                this.total.value4=res.data.value4
                this.total.value5=res.data.value5
                this.total.value6=res.data.value6
                this.total.value7=res.data.value7
                this.total.value8=res.data.value8
                this.total.value9=res.data.value9
                this.total.value10=res.data.value10

                this.total.value11=res.data.value11
                this.total.value12=res.data.value12
                this.total.value13=res.data.value13
                this.total.value14=res.data.value14
                this.total.value15=res.data.value15

                this.total.value16=res.data.value16
                this.total.value17=res.data.value17
                this.total.value18=res.data.value18
                this.total.value19=res.data.value19
                this.total.value20=res.data.value20

                this.total.value21=res.data.value21
                this.total.value22=res.data.value22
                this.total.value23=res.data.value23
                this.total.value24=res.data.value24
                this.total.value25=res.data.value25

                this.total.value26=res.data.value26
                this.total.value27=res.data.value27
                this.total.value28=res.data.value28
                this.total.value29=res.data.value29
                this.total.value30=res.data.value30
            })
        }
    }
}
</script>
<style scoped>
    .ta{
        margin-top: 10px; 
        margin-bottom: 10px;                   
        line-height: 38px;
        width: 500px;
        text-align: left;
        background-color:rgb(107, 141, 182);
        width: 685px;
    }
</style>